class ApiService {
  staging(access) {
    switch (access) {
      case 'oauth':
        return 'https://api.sanquawater.co.id/dev-oauth/'
      case 'oauth2':
        return 'https://api.sanquawater.co.id/dev-oauth2/'
      case 'proc':
        return 'https://api.sanquawater.co.id/dev-eprocurement/'
      // return 'http://localhost:9191/api/procurement/v1/'
      case 'esanqua':
        return 'https://api.sanquawater.co.id/dev-esanqua/'
      // return 'http://localhost:1193/api/esanqua/v1/'
      case 'asset':
        return 'https://api.sanquawater.co.id/dev-esanqua-ams/'
      case 'hr':
        return 'https://api.sanquawater.co.id/dev-hr/'
      case 'hrs':
        // return 'http://10.10.20.5:8190/api/esanqua/hr/v1/'
        return 'https://api.sanquawater.co.id/dev-esanqua-hr/'
      case 'hrsV2':
        // return 'http://10.10.20.5:8289/api/hr/attendance/v2/'
        return 'https://api.sanquawater.co.id/dev-esanqua-attendance/'
      // case 'asset2':
      //   return 'https://api.sanquawater.co.id/dev-ams/'
      // case 'asset2':
      //   return 'http://10.10.20.8:1194/api/esanqua/v1/'
      case 'upload':
        return 'http://10.10.20.8:9192/api/procurement/v1/'
      case 'upload2':
        // return 'http://10.10.20.8:1192/api/upload/v1/'
        return 'https://api.sanquawater.co.id/dev-upload/'
      case 'upload3':
        return 'https://dev-api.sanquawater.co.id/dev-upload-excel/'
      case 'download':
        return 'http://10.10.20.8:1192/api/download/v1/'
      // return 'https://api.sanquawater.co.id/dev-download/'
      case 'odoo':
        return 'https://api.sanquawater.co.id/dev-odoo-gw/'
      case 'attendance':
        return 'http://10.10.20.6:2189/api/hr/v1/'
      case 'log':
        // return 'http://10.10.20.5:8191/api/esanqua/log/'
        return 'https://api.sanquawater.co.id/dev-log/'
      case 'oee':
        return 'http://10.10.20.5:2197/api/esanqua/oee/'
      // return 'http://10.10.20.5:1197/api/esanqua/oee/'
      case 'mms':
        return 'http://10.10.20.5:2197/api/esanqua/mms/v1/'
      // return 'http://10.10.20.5:1197/api/esanqua/mms/v1/'
      case 'grafana':
        return 'http://mms-dev.sanquawater.co.id/login/generic_oauth'
      case 'expedition':
        return 'https://api.sanquawater.co.id/dev-expedition/'
      // return 'http://10.10.20.5:1198/api/esanqua/expedition/v1/'
      // return 'http://localhost:1196/api/esanqua/expedition/v1/'
      case 'image':
        return 'https://dev-esanqua.sanquawater.co.id/image/ecatalogue/'
    }
  }

  development(access) {
    switch (access) {
      case 'oauth':
        return 'https://api.sanquawater.co.id/dev-oauth/'
      case 'oauth2':
        return 'https://api.sanquawater.co.id/dev-oauth2/'
      case 'proc':
        return 'https://api.sanquawater.co.id/dev-eprocurement/'
      case 'esanqua':
        return 'https://api.sanquawater.co.id/dev-esanqua/'
      // return 'http://localhost:1193/api/esanqua/v1/'
      case 'asset':
        return 'https://api.sanquawater.co.id/dev-esanqua-ams/'
      case 'hr':
        return 'https://api.sanquawater.co.id/dev-hr/'
      case 'hrs':
        // return 'http://10.10.20.5:8190/api/esanqua/hr/v1/'
        return 'https://api.sanquawater.co.id/dev-esanqua-hr/'
      case 'hrsV2':
        // return 'http://10.10.20.5:8289/api/hr/attendance/v2/'
        return 'https://api.sanquawater.co.id/dev-esanqua-attendance/'
      // case 'asset2':
      //   return 'https://api.sanquawater.co.id/dev-ams/'
      // case 'asset2':
      //   return 'http://10.10.20.8:1194/api/esanqua/v1/'
      case 'upload':
        return 'http://10.10.20.8:9192/api/procurement/v1/'
      case 'upload2':
        // return 'http://10.10.20.8:1192/api/upload/v1/'
        return 'https://api.sanquawater.co.id/dev-upload/'
      case 'upload3':
        return 'https://dev-api.sanquawater.co.id/dev-upload-excel/'
      case 'download':
        return 'http://10.10.20.8:1192/api/download/v1/'
      // return 'https://api.sanquawater.co.id/dev-download/'
      case 'odoo':
        return 'https://api.sanquawater.co.id/dev-odoo-gw/'
      case 'attendance':
        return 'http://10.10.20.6:2189/api/hr/v1/'
      case 'log':
        // return 'http://10.10.20.5:8191/api/esanqua/log/'
        return 'https://api.sanquawater.co.id/dev-log/'
      case 'oee':
        return 'http://10.10.20.5:1197/api/esanqua/oee/'
      case 'mms':
        return 'http://10.10.20.5:1197/api/esanqua/mms/v1/'
      case 'grafana':
        return 'http://mms-dev.sanquawater.co.id/login/generic_oauth'
      case 'expedition':
        // return 'https://api.sanquawater.co.id/dev-expedition/'
        return 'https://api.sanquawater.co.id/dev-tms/'
      // return 'http://localhost:1198/api/esanqua/expedition/v1/'
      case 'image':
        return 'https://dev-esanqua.sanquawater.co.id/image/ecatalogue/'
    }
  }

  production(access) {
    switch (access) {
      case 'oauth':
        return 'http://10.10.20.6:1189/api/oauth/v1/'
      case 'proc':
        return 'http://10.10.20.6:1191/api/procurement/v1/'
      case 'upload':
        return 'http://10.10.20.6:1192/api/procurement/v1/'
      case 'upload2':
        return 'http://10.10.20.6:1192/api/upload/v1/'
      case 'upload3':
        return 'http://10.10.20.6:1292/api/upload/v1/'
      case 'download':
        return 'http://10.10.20.6:1192/api/download/v1/'
      case 'hr':
        return 'http://10.10.20.6:2189/api/hr/v1/'
      case 'hrs':
        return 'http://10.10.20.6:1190/api/esanqua/hr/v1/'
      case 'attendance':
        return 'http://10.10.20.6:2189/api/hr/v1/'
      case 'esanqua':
        return 'http://10.10.20.6:1193/api/esanqua/v1/'
      case 'asset':
        return 'http://10.10.20.6:1194/api/esanqua/ams/v1/'
      case 'odoo':
        return 'http://10.10.20.6:1195/api/odoo/v1/'
      default:
        return 'http://localhost:1189/api/oauth/v1'
    }
  }

  domain(access) {
    switch (access) {
      case 'oauth':
        // return 'https://api.sanquawater.co.id/prod-oauth/'
        return 'https://api.sanquawater.co.id/oauth/'
      case 'proc':
        return 'https://api.sanquawater.co.id/eprocurement/'
      case 'upload':
        return 'https://api.sanquawater.co.id/'
      case 'upload2':
        return 'https://api.sanquawater.co.id/upload/'
      case 'upload3':
        return 'https://api.sanquawater.co.id/upload-excel/'
      case 'download':
        return 'https://api.sanquawater.co.id/download/'
      case 'hr':
        return 'https://api.sanquawater.co.id/hr/'
      case 'hrs':
        return 'https://api.sanquawater.co.id/esanqua-hr/'
      case 'attendance':
        return 'https://api.sanquawater.co.id/hr/'
      case 'esanqua':
        return 'https://api.sanquawater.co.id/esanqua/'
      case 'asset':
        return 'https://api.sanquawater.co.id/esanqua-ams/'
      case 'odoo':
        return 'https://api.sanquawater.co.id/odoo/'
      default:
        return 'https://api.sanquawater.co.id/oauth/'
    }
  }

  preproduction(access) {
    switch (access) {
      case 'oauth':
        return 'https://api.sanquawater.co.id/prod-oauth/'
      case 'oauth2':
        return 'https://api.sanquawater.co.id/prod-oauth2/'
      case 'proc':
        return 'https://api.sanquawater.co.id/eprocurement/'
      case 'esanqua':
        return 'https://api.sanquawater.co.id/esanqua/'
      case 'asset':
        return 'https://api.sanquawater.co.id/esanqua-ams/'
      case 'hr':
        return 'https://api.sanquawater.co.id/hr/'
      case 'hrs':
        return 'https://api.sanquawater.co.id/prod-esanqua-hr/'
      case 'hrsV2':
        return 'https://api.sanquawater.co.id/prod-esanqua-attendance-trial/'
      case 'upload':
        return 'https://api.sanquawater.co.id/'
      case 'upload2':
        return 'https://api.sanquawater.co.id/upload/'
      case 'upload3':
        return 'https://api.sanquawater.co.id/upload-excel/'
      case 'download':
        return 'https://api.sanquawater.co.id/download/'
      case 'odoo':
        return 'https://api.sanquawater.co.id/odoo/'
      case 'log':
        return 'https://api.sanquawater.co.id/prod-log/'
      case 'attendance':
        return 'https://api.sanquawater.co.id/hr/'
      case 'attendanceTrial':
        return 'https://api.sanquawater.co.id/prod-esanqua-attendance-trial/'
      case 'grafana':
        return 'http://mms-dev.sanquawater.co.id/login/generic_oauth'
      case 'oee':
        return 'http://10.10.20.5:2197/api/esanqua/oee/'
      case 'mms':
        return 'http://10.10.20.5:2197/api/esanqua/mms/v1/'
      default:
        return 'https://api.sanquawater.co.id/oauth'
    }
  }
}

export default new ApiService()
