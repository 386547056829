import { initializeApp } from 'firebase/app'
import {
  getMessaging,
  getToken,
  onMessage,
  isSupported
} from 'firebase/messaging'
import store from '../store'
import Swal from 'sweetalert2'
// firebase project eSanQua Web Client 1
const firebaseConfig = {
  apiKey: 'AIzaSyBCzTKEqZeG9ZppKoMtUqk0fz_9ez7H3xA',
  authDomain: 'sanqua-test-clientapp.firebaseapp.com',
  projectId: 'sanqua-test-clientapp',
  storageBucket: 'sanqua-test-clientapp.appspot.com',
  messagingSenderId: '516379745795',
  appId: '1:516379745795:web:66aec364e74b820656f836',
  measurementId: 'G-3R9KW2VYT3'
}

const initApp = initializeApp(firebaseConfig)

let messaging = null
isSupported()
  .then(res => {
    console.log('FCM SUPPORTED: ' + res)
    if (res) {
      messaging = getMessaging(initApp)
      Notification.requestPermission().then(permission => {
        if (permission === 'granted') {
          getToken(messaging, {
            vapidKey: process.env.VUE_APP_FCM_VAPID_KEY
          })
            .then(currentToken => {
              if (currentToken) {
                store.commit('setFcmToken', currentToken)

                onMessage(messaging, payload => {
                  console.log('Message received. ', payload)
                  store.dispatch('loadNotification')
                  store.dispatch('loadPermissionApprovalNeeds')
                  store.dispatch('loadLeaveApprovalNeeds')

                  Swal.fire({
                    position: 'top-end',
                    title: `<div style="font-size:12px; font-weight:bold; text-align:left;">${'SQA ATTENDANCE'}</div>`,
                    html: `<div style="width:200px; display: flex; justify-content:flex-start; align-items:flex-start;">
    <div style="font-size:13px;">${'You Have Notification'}</div>
    </div>`,
                    showConfirmButton: false,
                    timer: 3000,
                    toast: true
                    // heightAuto: false
                  })
                })
              } else {
                console.log(
                  'No registration token available. Request permission to generate one.'
                )
              }
            })
            .catch(err => {
              console.log(err)
            })
        }
      })
    }
  })
  .catch(err => {
    console.log('FCM ERROR: ' + err)
  })

export default messaging !== null ? messaging : initApp
