export default {
  state: {
    oeeId: null
  },
  mutations: {
    setOeeId(state, payload) {
      state.oeeId = payload
    }
  },
  getters: {
    getOeeId(state) {
      return state.oeeId
    }
  }
}
