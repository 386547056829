export default {
  state: {
    urlQuery: null
  },
  mutations: {
    setEmployeePageQuery(state, payload) {
      state.urlQuery = payload
    }
  },
  actions: {},
  getters: {
    getEmployeePageQuery(state) {
      return state.urlQuery
    }
  }
}
